<template>

<app-content-box title="Public Profile" icon="public" :freeze="status === $constants.status.loading">

	<app-input-text label="Name" v-model="model.displayname" :validation="$v.model.displayname" placeholder="Enter your name" maxlength="32" />

	<app-button text="Save" :disabled="!isValid" :loading="status === $constants.status.loading" v-on:click="onSubmitClick" :status="status" />

</app-content-box>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			standalone: true,
			status: false,
			model: {
				displayname: ''
			}
		}

	},
	
	validations: {
		model: {
			displayname: {
				required
			}
		}
	},

	created: function() {

		this.model.displayname = this.$store.getters['session/displayname']

	},

	methods: {

		onSubmitClick: function() {

			this.status = this.$constants.status.loading

			this.$api.put('session/profile', this.model).then(function() {

				this.status = this.$constants.status.success

			}.bind(this), function() {

				this.status = this.$constants.status.failed

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>
